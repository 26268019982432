import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { environment } from './environments/environment';
import './polyfills';
import { chooseTranslation } from "./initializers/choose-translation";
// import { setupSentry } from "./initializers/setup-sentry";

if (environment.production) enableProdMode();

document.addEventListener('DOMContentLoaded', async () => {
	await chooseTranslation();

	// if (!environment.development) setupSentry();

	const {AppModule} = await import("./app/app.module");

	platformBrowserDynamic()
		.bootstrapModule(AppModule)
		.then(() => console.log(`Bootstrap success`))
		.catch(err => console.error(err));
});
