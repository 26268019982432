import {LanguageLocale} from "../../languages/static/language-loaders";

export class LanguageHelpers {
	static switchToLanguage(language: LanguageLocale) {
		localStorage.setItem("language", language);
	}

	static getApplicationLanguage(): LanguageLocale {
		const defaultLang = "pt-BR";

		if(typeof window !== 'undefined'){
			const language = localStorage.getItem("language") as LanguageLocale;

			return language ?? defaultLang;
		}

		return defaultLang;
	}
}
